import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';

const App = React.lazy(() => import('./AppContainer'))

const loader = document.getElementById('spinner-loading-app')
const hideLoader = () => loader.classList.remove('loader-shown')
const showLoader = () => loader.classList.add('loader-shown')

// setTimeout(() => {
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div></div>} >
      <App hideLoader={hideLoader} showLoader={showLoader} />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
// }, 2000)

